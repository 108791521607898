import { ReactNode, useContext, useState } from 'react';
import React from 'react';
import { Me$MeCup } from '../../api/schema';
import styles from './AdminBanner.module.scss';
import { useTranslations } from 'cupman-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

var cn = require('classnames');


export const AdminContext = React.createContext<{isAdmin: boolean, overridePublished: boolean, setOverridePublished: (override:boolean) => void}>(null as any);
export const useAdmin = () => useContext(AdminContext);

export default function AdminBanner({children, meCup, noWebSockets_Element} : {children: ReactNode, meCup: Me$MeCup | undefined, noWebSockets_Element: JSX.Element | undefined}) {
  
  const [overridePublished, setOverridePublished] = useState<boolean>(false);

  const T = useTranslations("cmresults");
  const isAdmin =  meCup?.isAdmin;

  //console.log({noWebSockets_Element})

  

  return (<>
    <AdminContext.Provider value={{isAdmin: meCup?.isAdmin || false, overridePublished: overridePublished, setOverridePublished:setOverridePublished}}>
      {children}
    </AdminContext.Provider>
    {(isAdmin || noWebSockets_Element) && <div className={classNames("no-print",styles.banner)}>

      {!!noWebSockets_Element && <div className={cn("max-75", styles.content)} style={{margin: '0 0 .5rem 0', paddingBottom: '.5rem', borderBottom: '1px solid rgba(0,0,0,.3)'}}>{noWebSockets_Element}</div>}

      {isAdmin && <div className={cn("max-75", styles.content)}>
          <span className={styles.admin}>
            <FontAwesomeIcon icon={faCircleCheck} style={{fontSize: '1.2rem'}} /> {T('Administrator')}
          </span>
          <button onClick={() => {
              setOverridePublished(!overridePublished);
            }}>
            <span className={styles.icon_wrapper}>
            {overridePublished 
              ? <FontAwesomeIcon icon={faToggleOn} style={{color: 'green'}} />
              : <FontAwesomeIcon icon={faToggleOff} />
            }
            </span>
            <span>{T('Show everything as if it was published')}</span>
          </button> 
      </div>}
    </div>}
  </>)
}