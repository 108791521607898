import classNames from 'classnames';
import { isColorWhite } from '../../../utils';
import styles from './HorizontalbarMultiple.module.scss';

type BarMultibleProps = {
  value:number, 
  color: string
}

type BarsProps = {
  bars: BarMultibleProps[]
}

export default function HorizontalbarMultiple ({bars} : BarsProps) {

  const barsNoZeroValue = bars.filter(bar => bar.value > 0)


  return <div className={classNames(styles.bar_container)}>

    {barsNoZeroValue.map((bar, i) => {
      
      return <div key={i} style={{
        width: !isNaN(bar.value) ? `${bar.value * 100}%` : '0%',
        background: isColorWhite(bar.color) ? 'rgb(80,80,80)' : bar.color
        }} className={classNames(barsNoZeroValue.length === 1 && styles.one_bar)}>
      </div>
    })
    }
  </div>
}