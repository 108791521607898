import { faArrowUpRightFromSquare, faCirclePlay, faHistory, faRoute, faShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useTranslations } from "cupman-utils";
import { useParams } from "react-router-dom";
import { Division$table, Match,  useMatch } from "../../api/schema";
import { useCurrentTournament } from "../../App";
import Conference from "../../components/Conference/Conference";
import Game from "../../components/Game/Game";
import { GamesDay } from "../../components/Game/Games";
import LiveFeed from "../../components/LiveFeed/LiveFeed";
import MatchSummary from "../../components/MatchSummary/MatchSummary";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageHeaderVideo from "../../components/PageHeader/PageHeaderVideo";
import StreamingPlayer from "../../components/StreamingPlayer/StreamingPlayer";
import TeamMembers from "../../components/TeamMembers/TeamMembers";
import { ErrorBoundary } from "../../Error/ErrorBoundary";
import ErrorComponent from "../../Error/ErrorComponent";
import { videoThumbnail } from "../../utils";
import styles from './MatchPage.module.scss';
import MatchStatistics from "../../components/Statistics/MatchStatistics";
import TeamStatistics from "../../components/Statistics/TeamStatistics";
import MatchTeamsAwards from "../../components/MatchTeamsAwards/MatchTeamsAwards";
import LinkButton from "../../components/Button/LinkButton";
import CustomIconFa from "../../components/CustomIconFa/CustomIconFa";


export function MatchPage() {
  const params = useParams<{id:string}>();
  const matchId = parseInt(params.id || '0');

  const game = useMatch({
      id:matchId
    }, 
    Match.fragment(
      TeamMembers.fragment,
      PageHeaderVideo.fragment,
      
      Match.fragment(
        MatchSummary.fragment,
        LiveFeed.fragment,
        StreamingPlayer.fragment,
        MatchStatistics.fragment,
      ),
      {
        referees: [{referee:{}}],
        day: {},
        nextMatchLoser:{match:Game.Fragment},
        nextMatchWinner:{match:Game.Fragment},
        roundName: {},
        division: {
          ___on_Conference:{
            table:{
              rows: [{
                team: {
                  club: {
                    nation: {}
                  }
                }
              }],
            }
          }
        }
      }
    )
  );



  const T = useTranslations('cmresults')
  const t = useCurrentTournament();

  const solidSport = t.streamingInfo?.provider === 'solidsport' || t.streamingInfo?.provider === 'solidtango';
  const handbollPlay = t.streamingInfo?.provider === 'handbollplay';

  const before = !game.live && !game.finished;
  const live = game.live && !game.finished; 
  const finished = game.finished;
  const showResults = !game.division.category.hideMatchResults;
  const showTable = !game.division.category.hideConferenceResults;
  const showTeamMembers = game.feed.statistics.home.players.length > 0 || game.feed.statistics.away.players.length > 0

  const statistics = game.feed.statistics;



  // COMPONENTS ##############



  // LIVEFEED
  const feed = <ErrorBoundary fallback={({error, errorInfo}) => <div className='max-75' style={{marginTop: '3rem'}}>
    <ErrorComponent errorInfo={errorInfo} error={error} text={T('The live feed could not be loaded')} />
  </div>}>
      <LiveFeed game={game} />
  </ErrorBoundary>

  // TEAM STATISTICS TOTAL
  const teamStatistics = <ErrorBoundary fallback={({error, errorInfo}) => 
    <ErrorComponent error={error} errorInfo={errorInfo} text={T('The team statistics could not be loaded')} style={{marginTop: '3rem'}} />}
  >
    <TeamStatistics
      matchId={game.id}
      sportType={t.subcup.sport.type} 
    />
  </ErrorBoundary>

  // MATCH STATISTICS
  const matchStatistics = <MatchStatistics statistics={statistics} game={game} />

  // PLAYER STATISTICS
  const teamMembers = <TeamMembers game={game} />

  // DIVISION
  const tableDivision = "table" in game.division && game.division.size > 2 && showTable && <>
    <ErrorBoundary fallback={({error, errorInfo}) => 
      <ErrorComponent error={error} errorInfo={errorInfo} 
      text={T('The group could not be loaded')} style={{marginTop: '3rem'}} 
    />}>
      <h2 style={{textAlign: 'center', marginBottom: '.5rem', marginTop: '4rem', fontSize: 'var(--font-size-l)'}} className="custom-font">
        {T('Matchens grupp')}
      </h2>
      <div>
        <Conference 
          linkTeams={false}
          name={T.select(game.division.name) || T('Group')}
          table={game.division.table as Division$table<
            typeof Conference.Fragment
          >} 
          matchesCanTie={game.division.category.matchesCanTie}
          className={'full_width'}
          id={game.division.id}
        />
      </div>
    </ErrorBoundary>
  </>

  // PLAYOFF
  const playOff = game.division.__typename === "Playoff" && (game.nextMatchLoser || game.nextMatchWinner) && !game.finished && showTable && <>
  <h2 style={{textAlign: 'center', marginBottom: '2rem', marginTop: '4rem', fontSize: 'var(--font-size-l)'}} className="custom-font">
       <FontAwesomeIcon icon={faHistory}/> {T('Kommande slutspelsmatcher')}
  </h2>
  <GamesDay games={[game.nextMatchLoser?.match, game.nextMatchWinner?.match].filter(m=>!!m)} knownDivision /></>

  // / COMPONENTS ##############



  return <div className={classNames(styles.content_wrapper,game.video && styles.video)}>
    {//game.video 
      //? <PageHeaderVideo game={game} /> :
       <PageHeader
        heroImage={game.video ? videoThumbnail(game.video?.thumbnail, t.subcup.sport.type, 1200) : undefined}
        maxHeight="var(--match-header-max-height)"
        title={game.roundName
          ? T.select(game.division.stage.name) + ` - ${T.select(game.roundName.name)}`
          : T('Game in X',T.select(game.division.name))
        }
        subtitle={game.matchNr}
        crumbs={[{
          title: T('Results'),
          url: ''
          },
          {
            title: T.select(game.division.category.name),
            url: `categories/${game.division.category.id}/${game.division.stage.id}`
          }
        ]}
        headerFooter={<MatchSummary game={game} />}
        marginBottom="1.5rem"
      >
        {game.video && <div className={styles.video_container}> 
          <a 
            href={game.video.externalLink} 
            className={styles.video_link} 
            target="_blank" 
            rel="noreferrer"
          >
            <span className={classNames(styles.video_link_inner, solidSport && styles.solidSport_red, handbollPlay && styles.handbollPlay_blue)}>

              <FontAwesomeIcon 
                icon={faArrowUpRightFromSquare} 
                className={styles.video_external_link} 
              /> 
              <span className={styles.video_btn_txt}>
                {game.live && !game.finished ? T('Köp matchen nu') : T('Se matchen nu')} 
                {game.live && game.finished && <>
                  {handbollPlay ? <img className={styles.video_btn_play_icon} src='https://static.cupmanager.net/images/cm-results/handbollplay-icon-white.svg' alt="Handball Play"/>
                   : <FontAwesomeIcon className={styles.video_btn_play_icon} icon={faCirclePlay}/>}
                </>}
              </span>

            </span>
          </a>
          <div className={classNames(styles.video_solidSport)}>
          <span>{T('Matchen sänds live av')}</span> {
            solidSport ? <img src='https://static.cupmanager.net/images/cm-results/solid-sport-white.svg' alt={T('Solid Sport')}/>
              : handbollPlay ? <img src='https://static.cupmanager.net/images/cm-results/handbollplay-logo.svg' alt={T('Handboll Play')}/>
              : <span>{t.streamingInfo?.provider}</span>
            }
        </div>
      </div>}

      </PageHeader>
    }
    <>

      {game.arena && <div className={classNames(styles.buttonbar_outer, "no-print")}>
        <div className={styles.buttonbar}>
          <div>
            {t.mapPublished ? (<LinkButton size="lg" url={`/map/${game.arena.location.id}`} >
              <CustomIconFa className={styles.arenaIcon} icon='icon-arena' fontWeight="bold" white />
              <span>{game.arena.completeName}</span>
            </LinkButton>) : (<div> 
              <CustomIconFa className={styles.arenaIcon} icon='icon-arena' fontWeight="bold" />
              <span style={{paddingLeft: ".5rem"}}>{ T('Field')}: {game.arena.completeName}</span>
            </div>)}
          </div>
        </div>

      </div>}

      {t.refereesPublic && game.day?.refereeSchedulePublished && game.referees && game.referees.length > 0 && <div className="max-50">
        <h2 style={{textAlign: 'center', marginBottom: '.5rem', marginTop: '4rem', fontSize: 'var(--font-size-l)'}} className="custom-font">
          <FontAwesomeIcon icon={faShield}/> {T('Referees')}
        </h2>
        {game.referees.map(r => {
          return <div className={styles.referee}>
            <span className={styles.refereePosition}>{T.select(r.positionName)}</span> <span className={styles.refereeName}>{r.referee.name}</span>
            </div>
        })}
        </div>}

     
      {before && showTable && <>
        {teamStatistics}
        <div className='max-50'>
          {tableDivision}
        </div>
        {showResults && game.home.team && game.away.team && feed}
        <div className='max-50'>
          {playOff}
        </div>
      </>}

      {live && <>
        {showResults && feed}
        {showTeamMembers && teamMembers}
        {showResults && matchStatistics}
        {showTable && <>
          {teamStatistics}
          <div className='max-50'>
            {tableDivision}
            {playOff}
          </div>
        </>}
      </>}
      
      {finished && <>
        {showTeamMembers && teamMembers}
        {showResults && matchStatistics}
        {showResults && feed}
        {showTable && <>
          <div className='max-50'>
            {tableDivision}
            {playOff}
          </div>
          {teamStatistics}
        </>}
      </>}
    </>
    
  </div>
}