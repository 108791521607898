import classNames from 'classnames';
import { useTranslations } from 'cupman-utils';
import { Link } from 'react-router-dom';
import { StatisticsTool$AwardTypes, Team$Award } from '../../api/schema';
import styles from './TeamAwards.module.scss';

type Award = Team$Award & {
    teamName?: string,
    teamId?: number
  }

type props = {
  award: Award
}




const getFilenameSuffix = (placement:number) => {
  if(placement === 1) {
    return 'gold'
  } else if (placement === 2) {
    return 'silver'
  } else if (placement === 3) {
    return 'bronze'
  }

  return 'others';
}

export const awardImgPath = (awardType: StatisticsTool$AwardTypes, placement: number) => {
  const iconsPath = 'https://static.cupmanager.net/images/achievements';

  let fileName = awardType
    .replace('PerMatch','')
    .replace('conferencePlacement','placement')
    .replace('SingleMatch','')
    .replace('firstPoint','firstGoal')
    .replace('savePercentage','saves');
  
  if( fileName === 'previousParticipant'){
    fileName += '-other';
  } else if( awardType !== 'mostSupporters' && awardType !== 'greencards'){
    fileName += '-' +  getFilenameSuffix(placement)
  } 

  return `${iconsPath}/${fileName}.svg?v=4`
}

export default function AwardWithText({award} : props) {
  const T = useTranslations('cmresults')

  const glowClass = award.type === 'placement' 
    ? styles[`glow_${getFilenameSuffix(award.placement)}`] as string
    : award.type === 'conferencePlacement'
      ? styles[`glow_less_${getFilenameSuffix(award.placement)}`] as string
      : ''


  return <div className={styles.achievement_container}>
    <img src={awardImgPath(award.type, award.placement)} className={classNames(glowClass)} alt="" />

    <div>
      <h4 className="custom-font">
        <AwardTitle award={award}/>
      </h4>
      <p dangerouslySetInnerHTML={{ __html: `${T.select(award.name)}` }}></p>
    </div>
  </div>
}


export function AwardTitle({award} : props) {
  const T = useTranslations('cmresults')

  if(award.teamName) {
    if(award.placement === 1) {
      return <>{T('Gold')} (<Link className={styles.team_link} to={`/team/${award.teamId}`}>{award.teamName}</Link>)</>
    } else if (award.placement === 2) {
      return <>{T('Silver')} (<Link className={styles.team_link} to={`/team/${award.teamId}`}>{award.teamName}</Link>)</>
    } else if (award.placement === 3) {
      return <>{T('Bronze')} (<Link className={styles.team_link} to={`/team/${award.teamId}`}>{award.teamName}</Link>)</>
    }
    return <>{T('Place X', award.placement)} (<Link className={styles.team_link} to={`/team/${award.teamId}`}>{award.teamName}</Link>)</>
  } else {
    if(award.type === 'greencards'){
      return <>{T('Fair play')}</>
    } else if(award.type === 'mostSupporters'){
      return <>{T('Hängivna fans')}</>
    } else if(award.type === 'previousParticipant' || award.type === 'previousParticipantWithPlacement'){
      return <>{T('Stammislag')}</>
    } else if(award.type === 'threePointers' || award.type === 'threePointersPerMatch' || award.type === 'goalPercentage') {
      return <>{T('Mästerskyttar')}</>
    } else if(award.type === 'pointsPerMatch') {
      return <>{T('Poängmaskiner')}</>
    } else if(award.type === 'points') {
      return <>{T('Poängsamlare')}</>
    } else if(award.type === 'goals') {
      return <>{T('Målsamlare')}</>
    } else if(award.type === 'goalsPerMatch') {
      return <>{T('Målmaskiner')}</>
    } else if(award.type === 'savePercentage' || award.type === 'saves' as any) { // Not yet typed
      return <>{T('Vägg i målet')}</>
    } else if(award.type === 'shotsOnGoalPerMatch' || award.type === 'shotsOnGoal') {
      return <>{T('Anfallare')}</>
    } else if(award.type === 'mostScorers') {
      return <>{T('Vinnande laganda')}</>
    } else if(award.type === 'mostScorersSingleMatch') {
      return <>{T('Kraftsamling')}</>
    } else if(award.type === 'firstGoal' || award.type === 'firstPoint') {
      return <>{T('Snabbare än blixten')}</>
    } else if(award.placement === 0){
      return <></>;
    } else if(award.placement === 1) {
      return <>{T('Gold')}</>
    } else if (award.placement === 2) {
      return <>{T('Silver')}</>
    } else if (award.placement === 3) {
      return <>{T('Bronze')}</>
    }
    return <>{T('Place X', award.placement)}</>
  }
}