import React, { useEffect, useRef } from "react";
import { ReactNode } from "react";
import { browserName, fullBrowserVersion } from "react-device-detect";
import styles from './ErrorBoundary.module.scss';

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faRotate, faRotateRight } from "@fortawesome/pro-regular-svg-icons";
import SearchBar from "../components/Search/SearchBar";
import { v4 } from "uuid";
import { useCurrentTournament } from "../App";





export type ErrorType = Error & {retry?:()=>void};

type OuterProps = {
  children: ReactNode,
  onError?: (error:ErrorType, errorInfo:any) => boolean,
  fallback: ReactNode | (
    (p:{error:ErrorType, errorInfo:any}) => JSX.Element
  )
}

type InnerProps = OuterProps

type InnerState = {
  hasError: true,
  error: ErrorType,
  errorInfo: any,
  errorWasHandledByOnError: boolean
} | {
  hasError: false,
  error: null,
  errorWasHandledByOnError: false
}






// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component<InnerProps, InnerState> {

  state = { hasError: false as false, error: null, errorWasHandledByOnError: false } as InnerState;
  
  listener : any;

  componentDidMount() {

    const resetError = () => {
      if (this.state.hasError) this.setState({hasError:false, error:null})
    }

    this.listener = window.addEventListener('popstate', resetError);
    }
  componentWillUnmount() {
    const resetError = () => {
      if (this.state.hasError) this.setState({hasError:false, error:null})
    }

    this.listener = window.removeEventListener('popstate', resetError)
  }

  componentDidCatch(error:any, errorInfo:any) {

    let errorWasHandledByOnError = false;
    if(this.props.onError){
      errorWasHandledByOnError = this.props.onError(error,errorInfo);
    } 

    console.log("ErrorBoundary: caught error", error, errorInfo?.componentStack);
    
    if (error.retry) {
      var oldRetry = error.retry;
      error.retry = () => {
        oldRetry();
        this.setState({hasError:false, error:null, errorWasHandledByOnError})
      }
    }
    
    // You can also log the error to an error reporting service
    this.setState({hasError:true, error, errorInfo, errorWasHandledByOnError})
  }

  render() {
    if (this.state.hasError) {
      if (this.props.onError && this.state.errorWasHandledByOnError) {
        
        return <></>
        //throw new Promise(()=>{}); // Promise that never resolves. 
                                   // The onError callback should handle the error
      } else if (typeof this.props.fallback == "function") {
        const Fallback = this.props.fallback;
        return <Fallback error={this.state.error} errorInfo={this.state.errorInfo} />
      } else {
        return this.props.fallback;
      }

    }
    return this.props.children;
  }
}









// FALLBACK RENDER
export function ErrorFallbackNothingToSee ({error, errorInfo} : {error:ErrorType, errorInfo:any}) {

  console.log(
    `Error:
    ${error.name},
    ${error.message}.
    URL: ${window.location.href}.
    Browser: ${browserName} ${fullBrowserVersion}.`
  )

  return <></>
}








export function ErrorFallbackToSee ({error, errorInfo, message, title} : {error:ErrorType, errorInfo:any, message?: string, title?: string}) {

  // const params = useParams();
  const t = useCurrentTournament();
  const showSearch = !!t; // Object.getOwnPropertyNames(params).length !== 0;


  console.log(
    `Error:
    ${error.name},
    ${error.message}.
    URL: ${window.location.href}.
    Browser: ${browserName} ${fullBrowserVersion}.`
  )

  const devideAxis = 40;

  const animationContainer = useRef(null);

  // Check if we are inside element when mouse move
  const areWeInside = (e:MouseEvent, container:HTMLElement) => {

    const cBounds = container.getBoundingClientRect()
    const xMouse = e.x;
    const yMouse = e.y;

    if(yMouse > cBounds.y && yMouse < cBounds.y + cBounds.height) {
      if(xMouse > cBounds.x && xMouse < cBounds.x + cBounds.width) {
        return true
      }
      return false;
    }
    return false;
  }

  useEffect(() => {
    // Container
    const container = animationContainer.current as any;
    const time = 500
    const transition = `all ${time}ms ease`;
    
    // Children
    const imgContainer = container.querySelector('.error-imgWrapper');
    const grass = container.querySelector('.error-grass');
    const goal = container.querySelector('.error-goal');
    const goalie = container.querySelector('.error-goalie');
    const ball = container.querySelector('.error-ball');
    const article = container.querySelector('.error-message');

    // Set transition
    imgContainer.style.transition = transition;
    grass.style.transition = transition;
    goal.style.transition = transition;
    goalie.style.transition = transition;
    ball.style.transition = transition;
    article.style.transition = transition;
    

    const mouseEnter = () => {
      // When enter do following
      grass.style.transform = 'translateX(-50%) translateZ(250px) scale(1.05)'
      goal.style.transform = 'translateZ(120px) scale(1.1)'
      goalie.style.transform = 'translateZ(300px) scale(1.5) rotate(5deg)'
      goalie.style.left = 'calc(50% - 300px)'
      goalie.style.bottom = '55%'
      ball.style.transform = 'translateZ(400px) scale(1.3)'
      article.style.transform = 'translateX(-50%) translateZ(480px) scale(1)'
      article.style.boxShadow = '0px 17px 43px 3px rgba(var(--clr-gray-700), .4)'

      imgContainer.style.transition = 'none';
      grass.style.transition = 'none';
      goal.style.transition = 'none';
      goalie.style.transition = 'none';
      ball.style.transition = 'none';
      article.style.transition = 'none';
    }
    
    document.addEventListener('mousemove', (e:any) => {
      areWeInside(e, container) && mouseEnter();
      
      let xAxis = (e.x - container.clientWidth / 2) / devideAxis;
      let yAxis = (container.clientHeight / 2 - e.y) / devideAxis;

      imgContainer.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
    });


    // When leave do following
    document.addEventListener('mouseleave', (e:any) => {
      imgContainer.style.transition = transition;
      grass.style.transition = transition;
      goal.style.transition = transition;
      goalie.style.transition = transition;
      ball.style.transition = transition;
      article.style.transition = transition;
      
      
      imgContainer.style.transform = 'none'
      grass.style.transform = 'translateX(-50%) translateZ(0)'
      goal.style.transform = 'translateZ(0)'
      goalie.style.transform = ''
      goalie.style.left = ''
      goalie.style.bottom = ''
      ball.style.transform = 'translateZ(0)'
      article.style.transform = 'translateX(-50%) translateZ(0) scale(.7)'
      article.style.boxShadow = 'none'
    })    


  }, [animationContainer])

  const uuid = v4();

  return <div className={styles.error_wrapper}>
    <div className={styles.inner_wrapper} ref={animationContainer}>
      <div className={classNames('error-imgWrapper', styles.img_wrapper)}>
      <svg className={styles.sun} x="0px" y="0px"
          viewBox="0 0 500 250" >
        
        
          <g id="sun" transform="translate(250 0)">
            <radialGradient id={"SHINE_"+uuid} cx="100" cy="100" r="100" gradientUnits="userSpaceOnUse">
              <stop  offset="0.7568" style={{stopColor:'rgba(255,255,255,0)'}}/>
              <stop  offset="1" style={{stopColor:'#FFF3AB'}}/>
            </radialGradient>
            <linearGradient id={"SUN_"+uuid} gradientUnits="userSpaceOnUse" x1="27.6083" y1="98.0519" x2="169.5758" y2="98.0519">
              <stop  offset="0" style={{stopColor:'#FFF3AB'}}/>
              <stop  offset="1" style={{stopColor:'#FFF2C0'}}/>
            </linearGradient>

            <g className="inner-sun">
              <path className="shine" style={{fill:`url(#SHINE_${uuid})`}} d="M162.4,147.8l22,0.9l-9.8-15.5c-0.2-0.5-0.5-1-0.8-1.5v0c-3.1-4.9-1.6-11.1,3-14.2l20.3-8.1l-14.9-9.9
                c-0.5-0.5-1-0.9-1.5-1.3h0c-4.9-3.3-6-9.8-2.8-14.5l15-15.3l-17.3-2.9c-0.7-0.3-1.4-0.5-2.2-0.6h0c-6-1-9.7-6.9-8.4-12.6l7.3-19.6
                l-16.9,4.3c-0.7,0-1.5,0.1-2.2,0.3h0c-6.3,1.6-12.5-2.9-12.9-9.4v0c0-0.2,0-0.3,0-0.4l-1.2-19.1l-13.8,10.9
                c-0.6,0.3-1.1,0.7-1.7,1.1c-5.1,4-12.6,2.4-15.6-3.3l0,0c-0.2-0.5-0.5-0.9-0.8-1.3L98.6,0l-8.4,16c-0.3,0.4-0.6,0.9-0.8,1.3v0
                c-3,5.8-10.5,7.4-15.6,3.3h0c-0.5-0.4-1.1-0.8-1.7-1.1L58.3,8.6l-1.2,19.1c0,0.1,0,0.3,0,0.4v0c-0.4,6.5-6.6,11-12.9,9.4h0
                c-0.8-0.2-1.5-0.3-2.2-0.3l-16.9-4.3l7.3,19.6c1.3,5.7-2.4,11.6-8.4,12.6h0c-0.8,0.1-1.5,0.3-2.2,0.6L4.3,68.5l15,15.3
                c3.2,4.7,2.1,11.2-2.8,14.5c-0.6,0.4-1.1,0.8-1.5,1.3L0,109.5l20.3,8.1c4.6,3,6.1,9.3,3,14.2l0,0c-0.3,0.5-0.6,1-0.8,1.5l-9.8,15.5
                l22-0.9c5.4,0.9,9.3,6,8.4,11.7v0c0,0.3-0.1,0.6-0.1,0.9l-2.8,19l19.7-9.8c5-1.3,10.3,1.3,12.2,6.1l5.7,20.4l13.8-16.7
                c3.9-3.4,9.7-3.4,13.5,0l13.8,16.7l5.7-20.4c2-4.9,7.3-7.4,12.2-6.1l19.7,9.8l-2.8-19c0-0.3,0-0.6-0.1-0.9v0
                C153.2,153.8,157,148.7,162.4,147.8z">
                <animateTransform 
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="0 100 100"
                  to="360 100 100"
                  dur="80s"
                  repeatCount="indefinite"
                />

              </path>
              <circle className="sun-inside" style={{fill:`url(#SUN_${uuid})`}} cx="98.6" cy="98.1" r="71"/>
            </g>
          </g>
        </svg>
        <img src='https://static.cupmanager.net/images/cm-results/404/goal.svg' className={classNames('error-goal', styles.goal)} alt=""/>
        <img src='https://static.cupmanager.net/images/cm-results/404/grass.svg' className={classNames('error-grass', styles.grass)} alt="" />
        <img src='https://static.cupmanager.net/images/cm-results/404/goolie.svg' className={classNames('error-goalie', styles.goalie)} alt="" />
        <img src='https://static.cupmanager.net/images/cm-results/404/ball.svg' className={classNames('error-ball', styles.ball)} alt="" />
        <article className='error-message'>
          <h1>404 - You missed</h1>
          <p>Something went wrong. Honestly, it seems like you did something <i>wako</i>. However, it could be our fault too, maybe you slipped on the grass? The easiest thing to do is to blame the grass. We'll look into it.</p>
        </article>
      </div>
    </div>
    <div className={styles.tools}>
      <h2>Try something of the following:</h2>

      {showSearch && <SearchBar up white noMutation />}

      <div className={styles.buttons}>
        <button onClick={error.retry} className={styles.retry}><FontAwesomeIcon icon={faRotate} /> Try again</button> 
        <button onClick={() => window.location.href = window.location.href.split('#')[0]} className={styles.reload}><FontAwesomeIcon icon={faRotateRight} /> Reload</button> 
        <button onClick={() => window.location.href = '/'} className={styles.start}><FontAwesomeIcon icon={faHouse}/> Startpage</button>
      </div>
    </div>
  </div>
}